import React, { useState} from "react";
import classNames from "classnames";
// import ScrollReveal from "../../utils/ScrollReveal";
// import ReactGA from "react-ga";
import { Dialog, DialogTitle, DialogContent,DialogContentText,Typography} from "@mui/material";
// import Button from "../elements/Button";

// ReactGA.initialize(process.env.REACT_APP_GA_CODE);

// const trackPage = (page) => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };


const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);
  const [open, setOpen] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [shippingPolicy, setShippingPolicy] = useState(false);
  const [refundPolicy, setRefundPolicy] = useState(false);
  const [checked, setChecked] = useState(false);

  // const childRef = useRef();
  // let location = useLocation();

  // useEffect(() => {
  //   const page = location.pathname;
  //   document.body.classList.add("is-loaded");
  //   childRef.current.init();
  //   trackPage(page);
  // }, [location]);

  const handleOpen = () => {
    setOpen(true);
    setPrivacyPolicy(true);
    setChecked(false);
    setShippingPolicy(false);
    setRefundPolicy(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrivacyPolicy = () => {
    setPrivacyPolicy(true);
    setShippingPolicy(false);
    setRefundPolicy(false);
  };

  const handleShippingPolicy = () => {
    setShippingPolicy(true);
    setPrivacyPolicy(false);
    setRefundPolicy(false);
  };

  const handleRefundPolicy = () => {
    setRefundPolicy(true);
    setShippingPolicy(false);
    setPrivacyPolicy(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setOpen(false);
  };

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a href="https://facebook.com/">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Facebook</title>
              <path d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Twitter</title>
              <path d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://google.com/">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Instagram</title>
              <g>
                <circle cx="12.145" cy="3.892" r="1" />
                <path d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                <path d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z" />
              </g>
            </svg>
          </a>
        </li>
        <li>
          {/* <a href="">
            <u>Terms & Conditions</u>
          </a> */}
          <button variant="outlined" color="primary" style={{color:"#ffffff",backgroundColor:"#00468B",padding:"4px"}} onClick={handleOpen}>
              Terms and Conditions
          </button>
        </li>
      </ul>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h6" color={"black"} align="center">
            {/* {localStorage.getItem("operatorName")} */}
            <u><b>Terms And Conditions</b></u>
          </Typography>
        </DialogTitle>
        <DialogContent>

              <ul style={{ display: 'flex', listStyle: 'none', padding: 0,fontSize: "0.5rem",align:"center"}}>
                <li style={{ marginRight: '10px'}}><button onClick={handlePrivacyPolicy} style={{ height:"40px" }}>Privacy Policy</button></li>
                <li style={{ marginRight: '10px'}}><button onClick={handleShippingPolicy} style={{ height:"40px" }}>Shipping & Delivery Policy</button></li>
                <li><button onClick={handleRefundPolicy} style={{ height:"40px" }}>Cancellation & Refund Policy</button></li>
              </ul>
          {(refundPolicy)?(<DialogContentText>
            {/* <ScrollReveal
             ref={childRef}
              children={() => ( */}
             <Typography>
             <div style={{fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',fontSize: '0.8rem',fontWeight:'normal'}}>
             
             <h5 style={{ color: 'black' }}>Cancellation & Refund Policy</h5>
             <div style={{ padding: '10px', maxHeight: '400px', overflowY: 'auto'}}>
                <b>1. Refund of Account Balance:</b>
                <p style={{ textAlign: 'justify' }}>
                Subscriber of the Operators under <strong>{localStorage.getItem("operatorName")}</strong> (‘Company’) will be eligible to receive a refund of the Account Balance (‘Refund’) as per the terms provided in this Refund Policy.
                </p>
                <p style={{ textAlign: 'justify' }}>
                Subscribers will be eligible to receive a Refund of their Account Balance on deactivation/termination of his/her Subscriber Account (‘Cancellation’). Subscriber may cancel or completely discontinue availing all the Services provided by the Company by contacting the Company. However, Company reserves the right to refuse such deactivation request if the subscription of any of the Service is within the lock-in period or minimum period guarantee which was declared by the Company related to a specific Scheme. In such an instance the Company reserves the right to make the Cancellation request made by the Subscriber, effective from the date on which such lock-in period or minimum period guarantee comes to an end.
                </p>
                <b>2. No Refund in the following cases:</b>
                <p style={{ textAlign: 'justify' }}>
                On suspension of a particular Service for a temporary period when requested by a Subscriber;                </p>
                <p style={{ textAlign: 'justify' }}>
                Suspension of Service by the Company:<br/>
                (i)to carry out maintenance or testing;<br/>
                (ii) to safeguard the security and integrity of the Services or for technical purposes;<br/>
                (iii) if so directed by the government.
                </p>
                <b>3. How to Cancel Subscriber Account?</b>
                <p style={{ textAlign: 'justify' }}>
                  To cancel his/her Subscriber Account Subscriber can place a request for Cancellation with the Company by any of the following methods:
                </p>
                <p style={{ textAlign: 'justify' }}>By calling the respective operators support numbers or<br/>
                By Emailing to the email id provided in the payment page; or<br/>
                By contacting the respective collection agents.</p>
                <b>4. Process of Refund:</b>
                <p style={{ textAlign: 'justify' }}>Subscriber must initiate the Cancellation request by any one of the methods as provided in para ‘3’ above;</p>
                <p style={{ textAlign: 'justify' }}>Company shall process the Refund to Subscriber’s bank account hence Subscriber must submit a copy of the cancelled cheque to the Company to initiate NEFT and any other information/documents as required by the Company;</p>
                <p style={{ textAlign: 'justify' }}>Company will process the Refund amount within 20 Business Days of receipt of the Cancellation request, required documents and successful return of CPE by Subscriber to the Company (if applicable).</p>
              </div>
            </div> 
              {/* )} */}
           
            </Typography>
          </DialogContentText>):null}
          {(shippingPolicy)?(<DialogContentText>
            <Typography>
             <div style={{fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',fontSize: '0.8rem',fontWeight:'normal'}}>
             
             <h5 style={{ color: 'black' }}>Shipping & Delivery Policy</h5>
             <div style={{ padding: '10px', maxHeight: '400px', overflowY: 'auto'}}>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>
                  For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only. Orders are shipped within 0-2 Days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <strong>{localStorage.getItem("operatorName")}</strong> is not liable for any delay in delivery by the courier company / 
                  postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 0-2 Days from the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk on <strong>{localStorage.getItem("op_phone")} or {localStorage.getItem("op_email")}.</strong>
                </p>
              </div>
            </div> 
            </Typography>
          </DialogContentText>):null}
          {(privacyPolicy)?(<DialogContentText>
            <Typography>
             <div style={{fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',fontSize: '0.8rem',fontWeight:'normal'}}>
             
             <h5 style={{ color: 'black' }}>Privacy Policy</h5>
             <div style={{ padding: '10px', maxHeight: '400px', overflowY: 'auto'}}>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>
                  This privacy policy (“Policy”) relates to the manner <strong>{localStorage.getItem("operatorName")}</strong> (“we”, “us”, “our”) in which we use, handle and process the data that you provide us in connection with using the products or services we offer. By using this website or by availing goods or services offered by us, you agree to the terms and conditions of this Policy, and consent to our use, storage, disclosure, and transfer of your information or data in the manner described in this Policy.
                </p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>
                We are committed to ensuring that your privacy is protected in accordance with applicable laws and regulations. We urge you to acquaint yourself with this Policy to familiarize yourself with the manner in which your data is being handled by us.
                </p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>
                  <strong>{localStorage.getItem("operatorName")}</strong> may change this Policy periodically and we urge you to check this page for the latest version of the Policy in order to keep yourself updated.
                </p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}><b>What data is being collected</b></p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>We may collect the following information from you:</p>
                <ul>
                  <li>Name</li>
                  <li>Contact information including address and email address</li>
                  <li>Demographic information or, preferences or interests</li>
                  <li>Personal Data or Other information relevant/ required for providing the goods or services to you</li>
                  <li>The meaning of Personal Data will be as defined under relevant Indian laws</li>
                </ul>
                <br/>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>
                  <strong>Note:</strong> Notwithstanding anything under this Policy as required under applicable Indian laws, we will not be storing any credit card, debit card or any other similar card data of yours. Please also note that all data or information collected from you will be strictly in accordance with applicable laws and guidelines.
                </p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}><strong>What we do with the data we gather</strong></p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>
                  We require this data to provide you with the goods or services offered by us including but not limited, for the below set out purposes:
                </p>
                <ul>
                  <li>Internal record keeping.</li>
                  <li>For improving our products or services.</li>
                  <li>For providing updates to you regarding our products or services including any special offers.</li>
                  <li>To communicate information to you</li>
                  <li>For internal training and quality assurance purposes</li>
                </ul>
                <br/>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}><strong>Who do we share your data with</strong></p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>We may share your information or data with:</p>
                <ul>
                  <li>Third parties including our service providers in order to facilitate the provisions of goods or services to you, carry out your requests, respond to your queries, fulfil your orders or for other operational and business reasons.</li>
                  <li>With our group companies (to the extent relevant)</li>
                  <li>Our auditors or advisors to the extent required by them for performing their services</li>
                  <li>To communicate information to you</li>
                  <li>Governmental bodies, regulatory authorities, law enforcement authorities pursuant to our legal obligations or compliance requirements.</li>
                </ul>
                <br/>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}><strong>How we use cookies</strong></p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>
                  We use "cookies" to collect information and to better understand customer behaviour. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to avail our goods or services to the full extent. We do not control the use of cookies by third parties. The third party service providers have their own privacy policies addressing how they use such information.
                </p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}><strong>Your rights relating to your data</strong></p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}><i><u>Right to Review</u></i> - You can review the data provided by you and can request us to correct or amend such data (to the extent feasible, as determined by us). That said, we will not be responsible for the authenticity of the data or information provided by you.</p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}><i><u>Withdrawal of your Consent</u></i> - You can choose not to provide your data, at any time while availing our goods or services or otherwise withdraw your consent provided to us earlier, in writing to our email ID: <strong>{localStorage.getItem("op_email")}</strong> In the event you choose to not provide or later withdraw your consent, we may not be able to provide you our services or goods. Please note that these rights are subject to our compliance with applicable laws.</p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}><strong>How long will we retain your information or data?</strong></p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>We may retain your information or data (i) for as long as we are providing goods and services to you; and (ii) as permitted under applicable law, we may also retain your data or information even after you terminate the business relationship with us. However, we will process such information or data in accordance with applicable laws and this Policy.</p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}><strong>Data Security</strong></p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>We will use commercially reasonable and legally required precautions to preserve the integrity and security of your information and data.</p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}><strong>Queries/ Grievance Officer</strong></p>
                <p style={{ marginLeft: '2px', textAlign: 'justify' }}>For any queries, questions or grievances about this Policy, please contact us using the contact information provided on this website.</p>
              </div>
            </div> 
            </Typography>
          </DialogContentText>):null}
          {/* <div style={{ display: 'flex', alignItems: 'center',fontSize: '0.8rem' }}>
            <Checkbox checked={checked} onChange={handleChange} />
            <div>
              <p style={{ margin: 0 }}>I understand that the content provided is not legal advice, and by using</p>
            </div>
          </div> */}
          
        </DialogContent>
       
      </Dialog>
    </div>
  );
};

export default FooterSocial;
